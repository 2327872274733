<template>
  <div class="bet-list--inner">
    <template v-if="!$route.query.origin && $route.query.origin !== 'memo'">
      <div class="input-wrap">
        <input
          v-model="userQ"
          type="text"
          :placeholder="translate(translations, 'search user')"
          @change="searchUser"
        />
      </div>
    </template>

    <check-data-list
      v-if="isDataLoading || isEmpty"
      :is-loading="isDataLoading"
      :is-empty="isEmpty"
    />

    <div
      v-else
      class="betslip__items"
    >
      <betslip-card
        v-for="(slip, index) in betHistory"
        :key="index"
        :logs="slip"
        :game="$route.params.game"
        :show-buttons="true"
        @update="search()"
      >
        <template v-if="slip.bet_info && slip.bet_info.length > 0">
          <bets-card
            v-for="(bets, idx) in slip.bet_info"
            :key="idx"
            :logs="bets"
            :slip="slip"
            @trigger="trigger"
          />
        </template>
      </betslip-card>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapState, mapActions } from 'vuex'

//= components
import BetslipCard from '@/components/common/betslip/BetslipCard'
import BetsCard from '@/components/common/betslip/BetsCard'
import CheckDataList from '@/components/base/CheckDataList'

//= mixins
import { getStyles } from '@/assets/js/mixins/base/GetStyles'
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

//= translation
import { translations } from '@/assets/js/translations/Betslip'

import FormatAmount from '@/assets/js/filters/FormatAmount'

import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

export default {
  name: 'BetListNew',

  components: {
    BetslipCard,
    BetsCard,
    CheckDataList
  },

  filters: {
    FormatAmount,

    total (amounts) {
      if (!amounts || !amounts.length) {
        return 0
      }
      return amounts.reduce((e = 0, a = 0) => {
        return parseInt(e) + parseInt(a)
      })
    },

    teams (item, lang) {
      if (lang === 'en') {
        return `${item.home_team_name} vs ${item.away_team_name}`
      }

      if (lang === 'kr') {
        return `${item.home_team_name_kor ? item.home_team_name_kor : item.home_team_name }
          vs
        ${item.away_team_name_kor ? item.away_team_name_kor : item.away_team_name}`
      }
      return item
    }
  },

  mixins: [
    getStyles,
    getListStatus
  ],

  data () {
    const params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'created_at',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      translations,
      params,
      selectedCountry: '',
      selectedGameName: '',
      selectedLeague: '',
      leaguesList: [],
      autoCompletes: [
        {
          key: 'country',
          data: ['...loading']
        },
        {
          key: 'game_name',
          data: ['...loading']
        },
        {
          key: 'league',
          data: ['...loading']
        }
      ],

      filterBy: [
        {
          value: 'login_id',
          display: 'login id'
        },
        {
          value: 'login_name',
          display: 'login name'
        },
        {
          value: 'bet_amount',
          display: 'bet amount'
        },
        {
          value: 'win_amount',
          display: 'win amount'
        },
        {
          value: 'home_team',
          display: 'home team'
        },
        {
          value: 'away_team',
          display: 'away team'
        }
      ],

      filterByAlt: [
        {
          value: 'login_id',
          display: 'login id'
        },
        {
          value: 'login_name',
          display: 'login name'
        },
        {
          value: 'bet_amount',
          display: 'bet amount'
        },
        {
          value: 'win_amount',
          display: 'win amount'
        }
      ],

      dateBy: [
        {
          value: 'play_date',
          display: 'play date'
        },
        {
          value: 'created_at',
          display: 'bet date'
        },
        {
          value: 'result_date',
          display: 'result date'
        }
      ],

      status: '',
      filter: '',

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      searchValue: '',
      userQ: ''
    }
  },

  computed: {
    ...mapState('bet-history-new', ['matches', 'betHistory']),
    ...mapState('game', { gameTypes: 'types' }),
    ...mapState('config', ['config']),
    ...mapState('user', ['users']),

    language () {
      return this.config.lang || vueLocalStorage.getItem('adminLang')
    }
  },

  watch: {
    async searchValue (e) {
      this.isDataLoading = true
      this.showDataLoading = true
      const route = this.$route
      const isNewGame = ['casino', 'token', 'bet365'].includes(this.$route.params.game) && this.$route.query.gameID

      await this.getBetList({
        ...this.$route.query,
        ...(isNewGame && { gameType: route.params.game }),
        ...(isNewGame && { gameID: route.query.gameID }),
        ...(isNewGame && { prdID: route.query.prdID }),
        ...(isNewGame && { roundID: route.query.roundID }),
        ...(isNewGame && { matchID: route.query.matchID }),
        params: {
          ...(!isNewGame && { mgIds: route.query.mgIds }),
          ...(!isNewGame && { mgGame: route.query.mgGame }),
          ...(!isNewGame && { mgSel: route.query.mgSel }),
          ...(e && { filter_by: 'users.id' }),
          ...(e && { q: e })
        }
      })

      this.isDataLoading = false
      this.showDataLoading = false
      this.isEmpty = _isEmpty(this.betHistory)
    }
  },

  created () {
    this.search()
  },

  methods: {
    ...mapActions('bet-history-new', { getBetHistoryNew: 'get', getBetList: 'getBetList' }),
    ...mapActions('user', { getUsers: 'get' }),

    open (match) {
      this.$router.push({ path: '/bet-history-update/sports', query: { mgIds: match.bgIds } })
    },

    async search () {
      this.params.filterBy = _get(this.$route.query, 'filterBy', '')
      this.params.q = _get(this.$route.query, 'q', '')

      this.isDataLoading = true
      this.showDataLoading = true
      this.isEmpty = true

      const isNewGame = ['casino', 'token', 'bet365'].includes(this.$route.params.game) && this.$route.query.gameID
      const isOldGame = !isNewGame && this.$route.query.mgIds

      if (!isNewGame && !this.$route.query.mgIds) return

      const oldGameParams = {
        mgIds: this.$route.query.mgIds,
        mgGame: this.$route.query.mgGame,
        mgSel: this.$route.query.mgSel,
        filter_by: this.params.filterBy,
        q: this.params.q
      }

      await this.getBetList({
        ...this.$route.query,
        ...(isNewGame && { gameType: this.$route.params.game }),
        ...(isNewGame && { gameID: this.$route.query.gameID }),
        ...(isNewGame && { prdID: this.$route.query.prdID }),
        ...(isNewGame && { roundID: this.$route.query.roundID }),
        ...(isNewGame && { matchID: this.$route.query.matchID }),
        ...(isOldGame && { params: oldGameParams })
      })

      this.isDataLoading = false
      this.showDataLoading = false
      this.isEmpty = _isEmpty(this.betHistory)

      await this.getUsers({
        status: 'confirmed',
        params: {
          rows: 200,
          sortBy: 'id',
          sort: 'desc',
          filter_by: 'login_id'
        }
      })
    },

    trigger (q) {
      this.params = q || this.params
      this.params.country = this.selectedCountry || ''
      this.params.game = this.selectedGameName || ''
      this.params.league = this.selectedLeague || ''
      this.params.lang = this.language || 'en'
      this.$router.push({ query: this.params })
    },

    async searchSpecific (q) {
      await this.getUsers({
        status: 'confirmed',
        params: {
          rows: 200,
          sortBy: 'id',
          sort: 'desc',
          filter_by: 'login_id',
          q: q
        }
      })
    },

    searchUser () {
      this.searchValue = this.userQ
    }
  }
}
</script>
<style type="text/css" scoped>
  .input-wrap {  margin-bottom: 5px }
</style>
