export var getStyles = {
  methods: {
    getComputedStyles (type, refs) {
      var el = refs
      var style = getComputedStyle(el, null)

      return parseInt(style[type]) || 0
    },

    getPropertyValues (type, refs) {
      var el = refs
      var style = getComputedStyle(el, null).getPropertyValue(type)

      return style
    }
  }
}
